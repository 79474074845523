import { CATEGORY, SUB_CATEGORY } from "../common";
import { NavLink, useLocation, Link } from "react-router-dom";

export default function SubCategory({ categorySubed, refs, setNowSub }) {
  const reset = () => {
    setNowSub(categorySubed[0].category_seq);
  };

  return (
    <div className="m-dep2-category-menu ">
      {categorySubed.map((item, idx) => {
        const nowLink_1 = CATEGORY.get(item.category_seq);

        return (
          <div className="category-dep2-area" key={item.category_seq}>
            <button
              className="tit-link"
              ref={(el) => (refs.current[item.category_seq] = el)}
            >
              {item.category_name}
            </button>
            <ul className="category-dep2-menu">
              <li>
                <Link to={nowLink_1} className="btn" onClick={reset}>
                  ALL
                </Link>
              </li>
              {item.childs.map((link, index) => {
                const nowLink_2 = `${SUB_CATEGORY.get(item.category_seq)}${item.category_seq === 20 ? link.category_seq : ""}`;

                return (
                  <li key={index}>
                    <Link to={nowLink_2} className="btn" onClick={reset}>
                      {link.category_name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
    </div>
  );
}
